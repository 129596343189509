<script>
import RowTableCommon from "./Row.vue";

export default {
  name: "CellTableCommon",
  functional: true,
  props: {
    data: {
      type: Object,
      require: true,
    },
    size: {
      type: String,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    return createElement(
      "div",
      {
        style: { width: props.size, ...props.data.styles, ...data.style },
        class: {
          "table-common__cell": true,
          ...props.data.classes,
          ...data.class,
        },
      },
      props.data.rows
        ? props.data.rows.map((row) => {
            return createElement(
              RowTableCommon,
              {
                props: { data: row, columnSizes: props.data.columnSizes || [] },
                key: row.id,
                style: row.styles,
                class: row.classes,
              },
              row.value
            );
          })
        : props.data.value
    );
  },
};
</script>
