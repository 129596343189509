<template>
  <modal
    v-show="confirmData"
    :data="modalDesign"
    class="modal--selection"
    ref="modal"
  >
    <slot v-if="!confirmData || !confirmData.message" />
    <p v-else>{{ confirmData.message }}</p>
  </modal>
</template>

<script>
import modal from "./ModalProto";
import { mapState } from "vuex";

let popupController = null;

export default {
  name: "ConfirmModal",
  components: {
    modal,
  },
  computed: {
    ...mapState({
      confirmData: (state) => state.modalStore.confirmModalData,
    }),
    modalDesign() {
      return {
        title: this.confirmData?.title,
        hasOverlay: true,
        actions: [
          {
            id: "accept",
            title: "Да",
            classes: "modal-message__accept",
            handler: () => {
              const modal = this.$refs.modal;
              modal.close();
              popupController.resolve(true);
              this.$store.commit("modalStore/cleanConfirmModalData");
            },
          },
          {
            id: "cancel",
            title: "Отмена",
            classes: "modal-message__cancel",
            handler: () => {
              const modal = this.$refs.modal;
              modal.close();
              popupController.resolve(false);
              this.$store.commit("modalStore/cleanConfirmModalData");
            },
          },
        ],
      };
    },
  },
  methods: {
    confirm() {
      this.$refs.modal.open();
      let resolve;
      let reject;
      const popupPromise = new Promise((ok, cancel) => {
        resolve = ok;
        reject = cancel;
      });
      popupController = { resolve, reject };
      return popupPromise;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
