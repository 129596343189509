<script>
import CellTableCommon from "./Cell.vue";

export default {
  name: "RowTableCommon",
  functional: true,
  props: {
    data: {
      type: Object,
      require: true,
    },
    columnSizes: {
      type: Array,
      require: true,
    },
  },
  render(createElement, context) {
    const { props } = context;
    return createElement(
      "div",
      {
        class: { "table-common__row": true, ...props.data.classes },
        style: { ...props.data.styles },
      },
      props.data.cells.map((cell, index) => {
        return createElement(CellTableCommon, {
          props: { data: cell, size: props.columnSizes[index] },
          key: cell.id,
          style: cell.styles,
          class: cell.classes,
        });
      })
    );
  },
};
</script>
