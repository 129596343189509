<template>
  <div
    class="table-common"
    :class="{ 'table-common--collapsed': !isOpen }"
    :style="{ width }"
  >
    <div class="table-common__title" @click="stateToggle">
      <div
        v-for="title of settings.title.blocks"
        :key="title.id"
        class="table-common__title-item"
        :class="{ ...settings.title.classes }"
      >
        {{ title.value }}
      </div>
    </div>

    <div class="table-common__header">
      <Row :data="settings.header" :column-sizes="settings.columnSizes" />
    </div>
    <div class="table-common__body">
      <Row
        v-for="row of settings.body.rows"
        :key="row.id"
        :data="row"
        :column-sizes="settings.columnSizes"
      />
    </div>
  </div>
</template>

<script>
import Row from "./Row.vue";
import { settingsValidation } from "./validationRuls.js";

export default {
  name: "TableCommon",
  components: {
    Row,
  },
  props: {
    settings: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  created() {
    settingsValidation(this.settings);

    if (
      Object.prototype.hasOwnProperty.call(this.settings, "isOpenByDefault")
    ) {
      this.isOpen = this.settings.isOpenByDefault;
    }
  },
  computed: {
    width() {
      const totalSize = this.settings.columnSizes.reduce(
        (total, colSize) => total + parseInt(colSize),
        0
      );
      const TABLE_PADDING_SIZE = 20;
      return totalSize + TABLE_PADDING_SIZE + "px";
    },
  },
  methods: {
    stateToggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/css/variables.scss";

.table-common {
  width: min-content;
  padding: 10px;
  padding-top: 0px;
  border-radius: 7px;
  background: linear-gradient(
    $erp-primary-bg-color 45px,
    #f3f3f3 45px,
    #f3f3f3 calc(100% - 30px),
    $erp-primary-bg-color calc(100% - 30px)
  );
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.table-common__header,
.table-common__body {
  background-color: #f3f3f3;
  .table-common--collapsed & {
    display: none;
  }
}

.table-common__header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 10px;
  font-weight: 700;
}

.table-common__title {
  background-color: $erp-primary-bg-color;
  color: #f3f3f3;
  padding: 4px 0;
  cursor: pointer;
  user-select: none;
}

.table-common__row {
  display: flex;
  min-height: 30px;
}

.table-common__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
