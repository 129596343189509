<template>
  <div class="container">
    <div class="planning-heading">
      <h2>Планирование:</h2>
      <div
        v-for="tab of tabs"
        :key="tab.id"
        class="planning-heading__tab"
        :class="{ 'planning-heading__tab--active': activeTab === tab.id }"
        @click="selectTab(tab.id)"
      >
        {{ tab.label }}
      </div>
    </div>
    <template v-if="activeTab === 'summary' && hasAccessToSummary('summary')">
      <div class="planning-content__filters">
        <CheckboxFilterModal
          title="Направления"
          :list="getActivitiesForSelect"
          :with-approve-button="true"
          :all-set-at-initialization="true"
          class="planning-content__filters-item"
          @approve="setActivitiesFilter"
        />
        <Select
          default="Занятость"
          showModel="label"
          :mode="['default']"
          :options="employment"
          class="planning-content__filters-item"
          @selectOption="setEmployment"
        />
      </div>
      <SummaryActivityTable
        v-for="activity of summaryActivities"
        :key="activity.id"
        :activity="activity"
        :dates="dates"
        class="planning-content__summary-table"
      />
    </template>
    <template
      v-if="activeTab === 'by-months' && hasAccessToSummary('by-months')"
    >
      <div class="actions-block">
        <DateSelect mode="select" :dates="planningsDates" @setDate="setDate" />
        <div class="actions-block__buttons">
          <Button
            :isLoading="isLoadingCreate"
            theme="common"
            @click="createNewPlanning"
          >
            Создать планирование
          </Button>
          <Button
            v-show="isExistDeleteButton"
            :isLoading="isLoadingDelete"
            theme="common"
            @click="deleteLastPlanning"
          >
            Удалить последнее планирование
          </Button>
        </div>
      </div></template
    >
    <ConfirmModal ref="confirmModal">
      <p>Вы точно хотите удалить планирование для даты {{ lastDateLabel }}?</p>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getDateFromPlanning } from "@/assets/utils";
import { ROLES } from "@/assets/enums";
import { SetEx } from "@/assets/dataStructs";
import { MONTHS } from "@/assets/constants.js";

import ConfirmModal from "@/components/modal/ConfirmModal";
import DateSelect from "@/components/DateSelect";
import CheckboxFilterModal from "@/components/CheckboxFilterModal";
import SummaryActivityTable from "@/components/SummaryActivityTable";
import Select from "@/components/Select";
import Button from "@/components/Button";
import loadPageMixin from "@/mixins/loadPageMixin";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default {
  name: "planning",
  components: {
    ConfirmModal,
    DateSelect,
    CheckboxFilterModal,
    SummaryActivityTable,
    Select,
    Button,
  },
  data() {
    return {
      isLoadingCreate: false,
      isLoadingDelete: false,
      activeTab: "by-months",
      summaryActivitiesFilters: {
        fulltime: null,
        activity_ids: null,
      },
      employment: [
        { id: "none", label: "Не выбрано", value: null },
        { id: "full-time", label: "Full time", value: true },
        { id: "part-time", label: "Part time", value: false },
      ],
    };
  },
  mixins: [loadPageMixin],
  async created() {
    await this.loadServerTime();
  },
  watch: {
    "me.groups": {
      handler() {
        this.activeTab = this.isInGroup([ROLES.FOUNDER, ROLES.PM])
          ? "by-months"
          : "summary";
      },
      immediate: true,
    },
    activeTab: {
      async handler(tabName) {
        if (tabName === "summary") {
          await this.loadSummaryStaffActivities();
          await this.loadActivitiesList();
        } else {
          if (!this.$route.query.page) {
            await this.loadPlanningsList();
          }
        }
      },
      immediate: true,
    },
    summaryActivitiesFilters: {
      async handler(filters) {
        const selectedFilters = Object.entries(filters).reduce(
          (result, [key, value]) => {
            if (value !== null) {
              result[key] =
                typeof value === "boolean" ? value.toString() : value;
            }
            return result;
          },
          {}
        );
        await this.loadSummaryStaffActivities(selectedFilters);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      planningsList: (state) => state.planningsStore.planningsList,
      summaryActivities: (state) => state.planningsStore.summaryActivities,
      me: (state) => state.userStore.me,
      serverTime: (state) => state.productionCalendarStore.serverTime,
    }),
    ...mapGetters({
      isInGroup: "userStore/isInGroup",
      getActivitiesForSelect: "planningsStore/getActivitiesForSelect",
    }),
    tabs() {
      const tabs = [
        {
          id: "summary",
          label: "Сводка",
          isHidden: !this.hasAccessToSummary("summary"),
        },
        {
          id: "by-months",
          label: "По месяцам",
          isHidden: !this.hasAccessToSummary("by-months"),
        },
      ];
      return tabs.filter((tab) => !tab.isHidden);
    },
    planningsDates() {
      return this.planningsList.map((date) => ({
        id: date.id,
        label: getDateFromPlanning(date),
      }));
    },
    isExistDeleteButton() {
      let hasRemovedMonth = false;
      if (this.planningsList.length > 2) {
        const currentDate = dayjs.utc(this.serverTime).endOf("month");
        const lastMonth = dayjs
          .utc(
            `${this.planningsList[0].year.number}-${this.planningsList[0].name}`
          )
          .endOf("month");

        hasRemovedMonth = lastMonth.subtract(1, "months") > currentDate;
      }
      return this.me?.groups.includes(ROLES.FOUNDER) && hasRemovedMonth;
    },
    lastDateLabel() {
      return `"${MONTHS[this.planningsList[0]?.name - 1]} ${
        this.planningsList[0]?.year.number
      } год"`;
    },
    dates() {
      const processedDatesIds = new SetEx();
      const dates = [];

      this.summaryActivities.forEach((activity) => {
        activity.staff.forEach((employee) => {
          employee.sub_assignments.forEach((subAssignment) => {
            if (!processedDatesIds.has(subAssignment.month_id)) {
              processedDatesIds.add(subAssignment.month_id);
              dates.push({
                id: subAssignment.month_id,
                value: subAssignment.date,
              });
            }
          });
        });
      });
      return dates.sort(
        (a, b) => dayjs("01." + a.value) - dayjs("01." + b.value)
      );
    },
  },
  methods: {
    ...mapActions({
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadSummaryStaffActivities: "planningsStore/loadSummaryStaffActivities",
      loadActivitiesList: "planningsStore/loadActivitiesList",
      createPlanning: "planningsStore/createPlanning",
      setPageNumber: "planningsStore/setPageNumber",
      deleteMonthlyPlanning: "planningsStore/deleteMonthlyPlanning",
      loadServerTime: "productionCalendarStore/loadServerTime",
    }),
    hasAccessToSummary(tab) {
      const availableForRoles =
        tab === "summary"
          ? [ROLES.FOUNDER, ROLES.TM, ROLES.PMO]
          : [ROLES.FOUNDER, ROLES.PM];
      return this.isInGroup(availableForRoles);
    },
    setDate(date) {
      this.$router.push({ path: `/planning/${date.label}` });
    },
    selectTab(tabId) {
      this.activeTab = tabId;
    },
    async createNewPlanning() {
      try {
        this.isLoadingCreate = true;
        const lastPlanningsIds = [...this.planningsList].map((date) => date.id);
        await this.createPlanning();
        await this.loadPlanningsList();
        this.isLoadingCreate = false;

        const newPlanning = this.planningsList.find(
          (date) => !lastPlanningsIds.includes(date.id)
        );
        const newDate = getDateFromPlanning(newPlanning);
        this.$router.push({ path: `/planning/${newDate}` });
      } catch (error) {
        this.isLoadingCreate = false;
      }
    },
    async deleteLastPlanning() {
      try {
        this.isLoadingDelete = true;
        this.$store.commit("modalStore/setConfirmModalData", {
          title: "Удаление планирования",
        });
        const isOk = await this.$refs.confirmModal.confirm();
        if (isOk) {
          await this.deleteMonthlyPlanning(this.planningsList[0].id);
        }
        this.isLoadingDelete = false;
      } catch (error) {
        this.isLoadingDelete = false;
      }
    },
    setEmployment(employment) {
      this.summaryActivitiesFilters.fulltime = employment.value;
    },
    setActivitiesFilter(ids) {
      this.summaryActivitiesFilters.activity_ids = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.actions-block {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.actions-block__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 310px;
}

.planning-heading {
  display: flex;
  gap: 30px;
  padding: 20px 0;
  margin: 20px 0;
  border-bottom: 1px solid $erp-primary-bg-color;
  font-size: 1.5rem;
  & h2 {
    margin: 0;
  }
}

.planning-heading__tab {
  border: 1px solid $erp-primary-bg-color;
  border-radius: 5px;
  padding: 4px 10px;
  cursor: pointer;
}

.planning-heading__tab--active {
  background-color: $erp-action-color;
}

.planning-content__filters {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.planning-content__filters-item {
  max-width: 300px;
  width: 100%;
}

.planning-content__summary-table:not(:last-child) {
  margin-bottom: 30px;
}
</style>
