<template>
  <TableCommon v-if="activity.staff.length" :settings="preparedData" />
</template>

<script>
import { Row, Cell } from "@/assets/dataStructs";
import TableCommon from "@/components/ui/TableCommon";

export default {
  name: "SummaryActivityTable",
  components: {
    TableCommon,
  },
  props: {
    activity: {
      type: Object,
      require: true,
    },
    dates: {
      type: Array,
      require: true,
    },
  },
  computed: {
    preparedData() {
      const DATES_COUNT = this.dates.length;

      const COLUMN_SIZES = {
        EMPLOYEE: "200px",
        QUALIFICATION: "150px",
        PROJECT: "230px",
        DATE: "80px",
        PROJECT_WITH_DATES: 230 + DATES_COUNT * 80 + "px",
      };

      const WORKLOAD = {
        MIN: 0,
        LIMITED: 40,
        OPTIMAL: 80,
        MAX: 100,
      };

      const header = new Row({
        id: "header",
        classes: { "table-summary__header": true },
        cells: [
          new Cell({ id: "employee", value: "Сотрудник" }),
          new Cell({ id: "qualification", value: "Квалификация" }),
          new Cell({
            id: "dates-header",
            columnSizes: [
              COLUMN_SIZES.PROJECT,
              ...new Array(DATES_COUNT).fill(COLUMN_SIZES.DATE),
            ],
            rows: [
              new Row({
                id: "dates-rows",
                cells: [
                  new Cell({ id: "project", value: "Проект" }),
                  ...this.dates.map(
                    (date) =>
                      new Cell({
                        id: date.id,
                        value: date.value,
                      })
                  ),
                ],
              }),
            ],
          }),
        ],
      });

      const body = { id: "body", rows: [] };
      this.activity.staff.forEach((employee) => {
        const employeeWorkload = new Map(); // нагрузка для текущего сотрудника
        const employeeMonthData = new Map(); // данные для ячейки, соответствущей определенному месяцу с информацией по plan_hours для каждого проекта
        const employeeProjects = []; // список всех проектов сотрудника
        const processedProjectsIds = new Set(); // хранилище для проверки был ли уже добавлен проект

        // считаем запланированные на каждый месяц часы суммарно по всем проектам
        employee.sub_assignments.forEach((subAssignment) => {
          if (!employeeWorkload.has(subAssignment.month_id)) {
            employeeWorkload.set(subAssignment.month_id, 0);
          }

          const employeeWorkloadByMonth = employeeWorkload.get(
            subAssignment.month_id
          );

          employeeWorkload.set(
            subAssignment.month_id,
            employeeWorkloadByMonth + subAssignment.plan_hours
          );

          // группируем запланированные часы на проектах по месяцам
          if (!employeeMonthData.has(subAssignment.month_id)) {
            employeeMonthData.set(subAssignment.month_id, new Map());

            employeeMonthData
              .get(subAssignment.month_id)
              .set(subAssignment.project_id, subAssignment.plan_hours);
          } else {
            const planningHoursForProject =
              employeeMonthData
                .get(subAssignment.month_id)
                .get(subAssignment.project_id) || 0;

            employeeMonthData
              .get(subAssignment.month_id)
              .set(
                subAssignment.project_id,
                planningHoursForProject + subAssignment.plan_hours
              );
          }

          // создаем список проектов сотрудника и сортируем по названию
          if (!processedProjectsIds.has(subAssignment.project_id)) {
            processedProjectsIds.add(subAssignment.project_id);
            employeeProjects.push({
              id: subAssignment.project_id,
              name: subAssignment.project,
            });
          }
        });

        // считаем нагрузку в процентах по месяцам
        employee.employee_months.forEach((month) => {
          if (!employeeWorkload.has(month.pc_month_id)) {
            employeeWorkload.set(month.pc_month_id, WORKLOAD.MIN);
            return;
          }
          if (!month.available_hours) {
            employeeWorkload.set(month.pc_month_id, WORKLOAD.MAX);
            return;
          }

          employeeWorkload.set(
            month.pc_month_id,
            (employeeWorkload.get(month.pc_month_id) / month.available_hours) *
              WORKLOAD.MAX
          );
        });

        const row = new Row({
          id: "employee-" + employee.id,
          classes: { "table-summary__row": true },
          cells: [
            new Cell({
              id: "fullname-" + employee.id,
              value: employee.full_name,
              classes: { "table-summary__employee": true },
            }),
            new Cell({
              id: "qualification-" + employee.id,
              value: employee.qualification,
              classes: { "table-summary__qualification": true },
            }),
          ],
        });

        const summaryCell = new Cell({
          id: "summary-" + employee.id,
          columnSizes: [
            COLUMN_SIZES.PROJECT,
            ...new Array(DATES_COUNT).fill(COLUMN_SIZES.DATE),
          ],
          classes: { "table-summary__projects-info": true },
          rows: [],
        });

        employeeProjects.forEach((project) => {
          const projectRow = new Row({
            id: "project-row-" + project.id,
            classes: { "table-summary__project": true },
            cells: [
              new Cell({
                id: project.id,
                value: project.name,
                classes: { "table-summary__project-name": true },
              }),
            ],
          });

          this.dates.forEach((date) => {
            const workloadByDate = employeeWorkload.get(date.id);

            projectRow.addCell(
              new Cell({
                id: "month-" + date.value,
                classes: {
                  "table-summary__cell--blocks": true,
                  "table-summary__cell--optimal":
                    workloadByDate >= WORKLOAD.OPTIMAL,
                  "table-summary__cell--limited":
                    WORKLOAD.OPTIMAL > workloadByDate &&
                    workloadByDate >= WORKLOAD.LIMITED,
                  "table-summary__cell--inadmissible":
                    WORKLOAD.LIMITED > workloadByDate,
                },
                value: employeeMonthData.get(date.id)?.get(project.id) || null,
              })
            );
          });

          summaryCell.addRow(projectRow);
        });

        row.addCell(summaryCell);
        body.rows.push(row);
      });

      return {
        header,
        body,
        id: this.activity.id,
        title: {
          id: "title",
          blocks: [{ id: this.activity.id, value: this.activity.role }],
          classes: { "table-summary__title": true },
        },
        columnSizes: [
          COLUMN_SIZES.EMPLOYEE,
          COLUMN_SIZES.QUALIFICATION,
          COLUMN_SIZES.PROJECT_WITH_DATES,
        ],
      };
    },
  },
};
</script>

<style lang="scss" scope>
.table-summary__title {
  text-align: center;
  font-weight: 600;
}

.table-summary__header {
  padding: 10px 0;

  & .table-common__cell:not(:last-child) {
    border-right: 1px solid rgb(34, 60, 80);
  }
}

.table-summary__row {
  padding: 2px 0;
  box-shadow: 2px 2px 8px 2px rgba(34, 60, 80, 0.2);
  border-radius: 5px;
}

.table-summary__row:not(:last-child) {
  margin-bottom: 10px;
}

.table-summary__projects-info {
  display: flex;
  flex-direction: column;
}

.table-summary__project {
  padding: 1px 0;
  flex-grow: 1;
}

.table-summary__employee,
.table-summary__qualification,
.table-summary__project-name {
  justify-content: flex-start;
  text-align: left;
  padding: 0 8px;
}

.table-summary__cell {
  &--blocks {
    padding: 4px 0;
    border-right: 1px solid #ffffff;
  }
  &--optimal {
    background-color: #b6d7a8;
  }
  &--limited {
    background-color: #f6b26b;
  }
  &--inadmissible {
    background-color: #ea9999;
  }
}
</style>
