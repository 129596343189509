const validation = {
  titles: {
    type: "object",
    require: false,
  },
  header: {
    type: "object",
    require: true,
  },
  body: {
    type: "object",
    require: true,
  },
  columnSizes: {
    type: "array",
    require: true,
  },
  isOpenByDefault: {
    type: "boolean",
    require: false,
  },
};

export function settingsValidation(settings) {
  Object.entries(validation).forEach(([key, value]) => {
    if (value.require && !settings[key]) {
      console.error(
        `[${this.$options._componentTag}]: Поле ${key} для пропса settings обязательное.`
      );
      return;
    }
    if (value.type) {
      if (value.type === "array") {
        if (!Array.isArray(settings[key])) {
          console.error(
            `[${
              this.name
            }]: Поле ${key} должно иметь тип 'array', а имеет тип '${typeof settings[
              key
            ]}'.`
          );
        }
      } else if (!value.require && typeof settings[key] === "undefined") {
        return;
      } else if (typeof settings[key] !== value.type) {
        console.error(
          `[${this.name}]: Поле ${key} должно иметь тип '${
            value.type
          }', а имеет тип '${typeof settings[key]}'.`
        );
      }
    }
  });
}
